<template>
  <b-form-group
    label="Vites Tipi"
    label-for="id_com_gear"
  >
    <v-select
      id="id_com_gear"
      v-model="detailFilter.id_com_gear"
      placeholder="Seçiniz"
      :options="dataList"
      label="title"
      :reduce="item => item.id"
    />
  </b-form-group>
</template>
<script>
import { BFormGroup } from 'bootstrap-vue'
import vSelect from 'vue-select'

export default {
  name: 'Gears',
  components: {
    BFormGroup,
    vSelect,
  },
  computed: {
    dataList() {
      return this.$store.getters['gears/getGears']
    },
    detailFilter() {
      return this.$store.getters['rentalStocks/detailFilter']
    },
  },
  created() {
    this.getDataList()
  },
  methods: {
    getDataList() {
      this.$store.dispatch('gears/gearsList', {
        select: [
          'com_gear.id AS id',
          'com_gear.title AS title',
        ],
      })
    },
  },
}
</script>
