<template>
  <div>
    <b-row class="match-height d-flex align-items-center">
      <b-col>
        <b-input-group>
          <b-form-input
            v-model="searchData.keyword"
            placeholder="Araç Kodu, Plaka, Şase..."
            @keydown.enter="searchAction"
          />
          <b-input-group-append>
            <b-button
              variant="primary"
              @click="searchAction"
            >
              <FeatherIcon icon="SearchIcon" />
            </b-button>
          </b-input-group-append>
        </b-input-group>
      </b-col>
      <b-col cols="auto">
        <b-form-checkbox
          v-model="detailFilter"
          switch
          inline
          @click="detailFilter = !detailFilter"
        >
          Detaylı Arama
        </b-form-checkbox>
      </b-col>
      <b-col
        v-if="waitDataCounts"
        cols="auto"
      >
        <b-button
          variant="danger"
          :to="$route.path + '/search'"
          :disabled="!waitDataCounts"
        >
          ({{ waitDataCounts }}) Yeni Stok Ekle
        </b-button>
      </b-col>
    </b-row>
    <b-row v-if="detailFilter">
      <b-col cols="12">
        <hr>
        <stock-status />
      </b-col>
      <b-col
        cols="12"
        md="3"
      >
        <brands />
      </b-col>
      <b-col
        cols="12"
        md="3"
      >
        <models />
      </b-col>
      <b-col
        cols="12"
        md="3"
      >
        <fuels />
      </b-col>
      <b-col
        cols="12"
        md="3"
      >
        <gears />
      </b-col>
      <b-col
        cols="12"
        md="6"
      >
        <start-date />
      </b-col>
      <b-col
        cols="12"
        md="6"
      >
        <end-date />
      </b-col>
      <b-col
        cols="12"
        class="text-center"
      >
        <b-button
          variant="primary"
          @click="filterAction"
        >
          <FeatherIcon icon="FilterIcon" />
          Filtrele
        </b-button>
      </b-col>
    </b-row>
  </div>
</template>

<script>
import {
  BRow,
  BCol,
  BFormInput,
  BInputGroup,
  BInputGroupAppend,
  BFormCheckbox,
  BButton,
} from 'bootstrap-vue'

import StockStatus from '@/views/Rental/Stocks/Index/SearchBar/StockStatus.vue'
import Brands from '@/views/Rental/Stocks/Index/SearchBar/Brands.vue'
import Models from '@/views/Rental/Stocks/Index/SearchBar/Models.vue'
import Fuels from '@/views/Rental/Stocks/Index/SearchBar/Fuels.vue'
import Gears from '@/views/Rental/Stocks/Index/SearchBar/Gears.vue'
import StartDate from '@/views/Rental/Stocks/Index/SearchBar/StartDate.vue'
import EndDate from '@/views/Rental/Stocks/Index/SearchBar/EndDate.vue'

export default {
  name: 'SearchBar',
  components: {
    BRow,
    BCol,
    BFormInput,
    BInputGroup,
    BInputGroupAppend,
    BFormCheckbox,
    BButton,
    StockStatus,
    Brands,
    Models,
    Fuels,
    Gears,
    StartDate,
    EndDate,
  },
  props: {
    searchAction: {
      type: Function,
      required: true,
    },
    filterAction: {
      type: Function,
      required: true,
    },
  },
  data() {
    return {
      detailFilter: false,
    }
  },
  computed: {
    searchData() {
      return this.$store.getters['rentalStocks/searchData']
    },
    waitDataCounts() {
      return this.$store.getters['rentalStocks/waitDataCounts']
    },
  },
}
</script>
