<template>
  <b-row class="mt-1">
    <b-col
      v-for="counter in dataCounters"
      :key="counter.id"
      cols="12"
      md="4"
      class="mb-1"
    >
      <div class="border rounded p-1 text-center d-flex justify-content-between align-items-center">
        <div class="text-primary font-weight-bold">
          {{ counter.title }}
        </div>
        <div class="font-weight-bolder">
          {{ counter.count }}
        </div>
      </div>
    </b-col>
  </b-row>
</template>

<script>
import { BRow, BCol } from 'bootstrap-vue'

export default {
  name: 'Counters',
  components: {
    BRow, BCol,
  },
  computed: {
    dataCounters() {
      return this.$store.getters['rentalStocks/dataCounters']
    },
  },
}
</script>
