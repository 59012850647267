<template>
  <b-form-group
    label="Tescil Tarihi Başlangıç"
    label-for="sdate"
  >
    <b-form-datepicker
      id="sdate"
      v-model="detailFilter.sdate"
      v-bind="{labelNoDateSelected: 'Tarih Seçiniz',labelHelp: 'Ok tuşlarıyla takvimde gezinin'}"
      locale="tr"
      start-weekday="1"
    />
  </b-form-group>
</template>

<script>
import {
  BFormGroup, BFormDatepicker,
} from 'bootstrap-vue'

export default {
  name: 'StartDate',
  components: {
    BFormGroup,
    BFormDatepicker,
  },
  computed: {
    detailFilter() {
      return this.$store.getters['rentalStocks/detailFilter']
    },
  },
}
</script>
