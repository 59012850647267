<template>
  <b-form-group
    label="Marka"
    label-for="id_com_swap_brand"
  >
    <v-select
      id="id_com_swap_brand"
      v-model="detailFilter.id_com_swap_brand"
      placeholder="Seçiniz"
      :options="dataList"
      label="title"
      :reduce="item => item.id"
      @input="getModels"
    />
  </b-form-group>
</template>
<script>
import { BFormGroup } from 'bootstrap-vue'
import vSelect from 'vue-select'

export default {
  name: 'Brands',
  components: {
    BFormGroup,
    vSelect,
  },
  computed: {
    dataList() {
      return this.$store.getters['swapbrands/getSwapbrands']
    },
    detailFilter() {
      return this.$store.getters['rentalStocks/detailFilter']
    },
  },
  created() {
    this.getDataList()
  },
  methods: {
    getDataList() {
      this.$store.dispatch('swapbrands/swapbrandsList', {
        select: [
          'com_swap_brand.id AS id',
          'com_swap_brand.name AS title',
        ],
      })
    },
    getModels() {
      this.detailFilter.id_com_swap_model = null
      if (this.detailFilter.id_com_swap_brand) {
        this.$store.dispatch('swapmodels/swapmodelsList', {
          select: [
            'com_swap_model.id AS id',
            'com_swap_model.title AS title',
          ],
          where: {
            'com_swap_model.id_com_swap_brand': this.detailFilter.id_com_swap_brand,
          },
        })
      }
    },
  },
}
</script>
