<template>
  <b-form-group
    label="Model"
    label-for="id_com_swap_model"
  >
    <v-select
      id="id_com_swap_model"
      v-model="detailFilter.id_com_swap_model"
      placeholder="Seçiniz"
      :options="dataList"
      label="title"
      :reduce="item => item.id"
      :disabled="!detailFilter.id_com_swap_brand"
    />
  </b-form-group>
</template>
<script>
import { BFormGroup } from 'bootstrap-vue'
import vSelect from 'vue-select'

export default {
  name: 'Models',
  components: {
    BFormGroup,
    vSelect,
  },
  computed: {
    dataList() {
      return this.$store.getters['swapmodels/getSwapmodels']
    },
    detailFilter() {
      return this.$store.getters['rentalStocks/detailFilter']
    },
  },
}
</script>
